(function headerScript($, Drupal) {
  Drupal.behaviors.header = {
    attach(context) {
        var $offCanvas = $('.header__off-canvas');
        var $offCanvasTriggerOpen = $('#mega-menu-open');
        var $offCanvasTriggerClose = $('#mega-menu-close');

        function showOffCanvasItems() {
          $offCanvas.addClass('header__off-canvas--is-on-canvas');
          $offCanvas.removeClass('header__off-canvas--will-be-off-canvas');
          $offCanvasTriggerClose.focus();
        }

        function showOffCanvas() {
          // You can't use transition from display: none to display: block.
          // So, we add a class which has display: block, then wait 200ms and 
          // add the next class, that allows our transition for the menu.
          
          $offCanvas.removeClass('header__off-canvas--is-off-canvas');
          $offCanvas.addClass('header__off-canvas--will-be-on-canvas');
          setTimeout(showOffCanvasItems, 200);
        }
        
        function hideOffCanvasItems() {
          $offCanvas.removeClass('header__off-canvas--will-be-on-canvas');
          $offCanvas.addClass('header__off-canvas--is-off-canvas');
          $offCanvasTriggerOpen.focus();
        }

        function hideOffCanvas() {          
          $offCanvas.addClass('header__off-canvas--will-be-off-canvas');
          $offCanvas.removeClass('header__off-canvas--is-on-canvas');
          setTimeout(hideOffCanvasItems, 200);
        }

        $offCanvasTriggerOpen.click(function() {
          showOffCanvas();
        });

        $offCanvasTriggerClose.click(function() {
          hideOffCanvas();
        });

        // If you click or tab to anywhere on the page that is not the header__off-canvas div,
        // then hide the div.
        $(document).click(function(e) {
          if ($offCanvas.hasClass('header__off-canvas--is-on-canvas')) {
            if ($(e.target).closest($offCanvas).length != 1) {
              hideOffCanvas();
            } 
          }
        });
        $('a, button, input').focus(function() {
          if ($offCanvas.hasClass('header__off-canvas--is-on-canvas')) {
            if ($(this).closest($offCanvas).length === 0) {
              hideOffCanvas();
            } 
          }
        });

        // Call the hideOffCanvas function if the screen is wider than $bp--xlarge
        // Doing this with JS istead of CSS to make sure it sets the 'Open' button back.
        function checkWindowSize() {
          if ($(window).width() > 1024) {
            // Hardcoding add/remove here, so the header__off-canvas disappears immediately.
            // after the timout, we do the right things for show/hide/add/remove.
            $offCanvas.addClass('header__off-canvas--is-off-canvas');
            $offCanvas.removeClass('header__off-canvas--will-be-on-canvas');
            setTimeout(hideOffCanvas, 200);
          }
        }
        $(window).resize(function() {
          checkWindowSize();
        });

    },
  };
}(jQuery, Drupal));
